





































import { Component, Vue } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import { GetCategoryList } from "@/request/yian";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private data: any = [];
  private type: any = "";
  private goStudy(items: any, item: any) {
    let d: any = {};
    d.parent = {
      name: items.name,
      id: items.id,
    };
    d.child = item;
    this.$store.commit("updateJibing", d);
    this.$router.push("/main/zsyy/yian/yian-study?type=" + this.type);
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        search: "",
      },
    };
    GetCategoryList(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch((res: any) => {
        loading.close();
      });
  }
  private mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getData();
  }
}
